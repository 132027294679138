import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f824ea48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dashboard-wr" }
const _hoisted_2 = { class: "dashboard-header" }
const _hoisted_3 = { class: "title-main" }
const _hoisted_4 = { class: "subtitle-main" }
const _hoisted_5 = {
  key: 0,
  class: "dashboard-container"
}
const _hoisted_6 = { class: "dashboard-container-column" }
const _hoisted_7 = { class: "card card-dashboard dashboard-wrapper-lots" }
const _hoisted_8 = { class: "card card-dashboard dashboard-wrapper-chart" }
const _hoisted_9 = { class: "dashboard-container-column" }
const _hoisted_10 = { class: "card card-dashboard dashboard-wrapper-upcoming" }
const _hoisted_11 = { class: "card card-dashboard dashboard-wrapper-upcoming-activity" }
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString($setup.helloWords) + ", " + _toDisplayString($setup.name), 1),
      _createElementVNode("h3", _hoisted_4, "The time is " + _toDisplayString($setup.clock), 1)
    ]),
    (!$setup.hardcodedDashboard)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode($setup["DashboardLots"], { statusCounts: $setup.statusCounts }, null, 8, ["statusCounts"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode($setup["DashboardSale"], { data: $setup.lotsByMonth }, null, 8, ["data"])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode($setup["DashboardUpcoming"], { upcomingArray: $setup.upcoming }, null, 8, ["upcomingArray"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode($setup["DashboardActivity"], { actions: $setup.recentActivity }, null, 8, ["actions"])
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "hardcoded-dashboard",
          src: $setup.hardcodedDashboard,
          alt: "Dashboard"
        }, null, 8, _hoisted_12))
  ]))
}